
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

interface edit {
  name: string;
  position: string;
  bankCentralId: string;
  bankAreaId: string;
  bankBranchId: string;
  schoolId: string;
  note: string;
  email: any;
  phone: string;
  credentialId: {
    role: string;
    username: string;
    password: string;
  };
  address: {
    province: string;
    city: string;
    regency: string;
    detail: string;
  };
}

interface items {
  role: any;
  provinsiOptions: any;
  kabupatenOptions: any;
  kecamatanOptions: any;
}

export default defineComponent({
  name: "User",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading, 
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#C2C2C2";

    let items = reactive<items>({
      role: [],
      provinsiOptions: [],
      kabupatenOptions: [],
      kecamatanOptions: [],
    });

    const editValidator = Yup.object().shape({
      role: Yup.string().required("Role Wajib Diisi"),
      name: Yup.string().required("Nama User Wajib Diisi"),
      phone: Yup.string().required("Nomor Telepon Wajib Diisi"),
      email: Yup.string().required("Email Wajib Diisi").email(),
      province: Yup.string().required("Provinsi Wajib Diisi"),
      city: Yup.string().required("Kabupaten/ Kota Wajib Diisi"),
      regency: Yup.string().required("Kecamatan Wajib Diisi"),
    });

    const edit = reactive<edit>({
      name: "",
      position: "",
      bankCentralId: "",
      bankAreaId: "",
      bankBranchId: "",
      schoolId: "",
      note: "",
      email: "",
      phone: "",
      credentialId: {
        role: "",
        username: "",
        password: "**********",
      },
      address: {
        province: "",
        city: "",
        regency: "",
        detail: "",
      },
    });
    const getIdArea = () => {
      isLoading.value = true;
      ApiService.getWithoutSlug("crmv2/main_user/user/" + route.params.id)
        .then((res) => {
          edit.name = res.data.name;
          edit.position = res.data.position;
          edit.bankCentralId = res.data.bankCentralName;
          edit.bankAreaId = res.data.bankAreaName;
          edit.bankBranchId = res.data.bankBranchName;
          edit.schoolId = res.data.schoolName;
          edit.note = res.data.note;
          edit.email = res.data.email;
          edit.phone = res.data.phone;
          edit.credentialId.role = res.data.credentialId.role;
          edit.credentialId.username = res.data.credentialId.username;
          edit.address.province = res.data.address.province;
          edit.address.city = res.data.address.city;
          edit.address.regency = res.data.address.regency;
          edit.address.detail = res.data.address.detail;

          isLoading.value = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            router.push('/sign-in');
            isLoading.value = false;
          } else {
            toast.error(e.response.data.detail);
            isLoading.value = false;
          }
        });
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_BANK);
      setCurrentPageBreadcrumbs("User", ["Data"]);
      getIdArea();
    });

    return {
      submitButton,
      edit,
      editValidator,
      items,
      getIdArea,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
    };
  },
});
